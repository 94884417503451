body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6 !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loretta-banner {
  background-color: #cc3508; 

}

.loretta-articles {
  padding: 0.5rem;
}

.loretta-articles .card {
  width: 100%;
  background-color: whitesmoke;
  /* border: none; */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border: 1px solid rgba(255, 255, 255);
}

.loretta-content {
  padding: 5px;
}

.div-img {
  text-align: center;
}

.card-img {
  width: 80% !important
}

.loretta-content h3,
h4 {
  background-color: whitesmoke;
  text-align: center;
  padding: 0.5em;
}

.loretta-footer {
  background-color: #383838;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, !important
    /* font-family: "Poppins", sans-serif; */
}

.loretta-footer .row {
  padding: 1rem;
  text-align: center;
  color: lightgray;
}

.loretta-footer .col ul {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
}

/* .loretta-footer li {
  float: left;
  text-align: center;
  width: 5em;
} */

.loretta-footer a {
  text-decoration: none !important;
  color: lightgray !important;

}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7);
}

.div-services .card
{
  width: 11rem;
  max-height: 12rem;
  min-height: 12rem;
}


.social_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* margin-top: 25px; */

}

.social_box a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  border: 1px solid orangered;
  color: orangered;
  font-size: 18px;
  margin-left: 10px;
}

.loretta-footer i {
  color: orangered;
}



/*===============================
    Scroll Top
===============================*/
#scroll-Top .return-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  width: 40px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  background: orangered;
  border: 1px solid orangered;
  border-radius: 50%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  z-index: 2;
}

#scroll-Top .return-to-top:hover {
  background: #fff;
  color: orangered;
  border: 1px solid orangered;
}

#scroll-Top .return-to-top i {
  position: relative;
  bottom: 0;

}

#scroll-Top .return-to-top i {
  position: relative;
  animation-name: example;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 90px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.tittle-h3 {
  text-align: center;
  
}


.tittle-bar{
  /* background: #efe2e2; */
  /* padding: 0.5em; */
  text-align: center;
  /* font-size: 41px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif; */
  background: -webkit-linear-gradient(left, #383838, orangered);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#menu {
  background: #6D6D6D;
  height: 45px;
  padding-right: 18px;
  border-radius: 10px;
}

#menu ul,
#menu li {
  margin: 0 auto;
  padding: 0;
  list-style: none
}

#menu ul {
  width: 100%;
  text-align: right;
}

#menu li {
  display: inline-block;
  position: relative;
}

#menu a {
  display: block;
  line-height: 45px;
  padding: 0 14px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
}

#menu a.dropdown-arrow:after {
  content: "\25BE";
  margin-left: 5px;
}

#menu li a:hover {
  color: #0099CC;
  background: #F2F2F2;
  cursor: pointer;
}

#menu input {
  display: none;
  margin: 0;
  padding: 0;
  height: 45px;
  width: 100%;
  opacity: 0;
  cursor: pointer
}

#menu label {
  display: none;
  line-height: 45px;
  text-align: center;
  position: absolute;
  left: 35px
}

#menu label:before {
  font-size: 1.6em;
  color: #FFFFFF;
  content: "\2261";
  margin-left: 20px;
}

#menu ul.sub-menus {
  height: auto;
  overflow: hidden;
  width: 170px;
  background: #444444;
  position: absolute;
  z-index: 99;
  display: none;
}

#menu ul.sub-menus li {
  display: block;
  text-align: left;
  width: 100%;
}

#menu ul.sub-menus a {
  color: #FFFFFF;
  font-size: 16px;
}

#menu li:hover ul.sub-menus {
  display: block
}

#menu ul.sub-menus a:hover {
  background: #F2F2F2;
  color: #444444;
}

@media screen and (max-width: 800px) {
  #menu {
    position: relative
  }

  #menu ul {
    background: #444444;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 3;
    height: auto;
    display: none;
    text-align: left;    
  }

  #menu ul.sub-menus {
    width: 100%;
    position: static;
  }

  #menu ul.sub-menus a {
    padding-left: 30px;
  }

  #menu li {
    display: block;
    float: none;
    width: auto;    
  }

  #menu input,
  #menu label {
    position: absolute;
    top: 0;
    left: 0;
    display: block
  }

  #menu input {
    z-index: 4
  }

  #menu input:checked+label {
    color: #FFFFFF
  }

  #menu input:checked+label:before {
    content: "\00d7"
  }

  #menu input:checked~ul {
    display: block
  }
}



.div-loading-child {
  position: relative;
  top: 50%;
  left: 48%;
}

.div-loading-full {
  /* background: rgb(53, 126, 221, 0.5);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1000; */

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 1000;  
}


.container{
  list-style: none;
  margin: 0;
  padding: 0;

  /* max-width: 800px;
  background: #fff;
  width: 800px;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1); */
}


.container form{
  padding: 30px 0 0 0;
}
.container form .form-row{
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea{
  height: 70px;
}
.input-data input{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data input:focus ~ label, 
.input-data input:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  color: #383838;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before
{
  transform: scale(1);
}
.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.input-data textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data textarea:focus ~ label, 
.input-data textarea:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  color: #383838;
}
.input-data textarea:focus ~ .underline:before,
.input-data textarea:valid ~ .underline:before
{
  transform: scale(1);
}
.submit-btn .input-data textarea{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}


.label-select{
  transform: translateY(-20px);
  font-size: 14px;
  color: #383838;
  ;
}

.textarea textarea{
  resize: none;
  padding-top: 10px;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 14px;
  font-size: 16px;
  transition: all 0.3s ease;
}
/* .textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
} */
.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 25%!important;
}
.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}

@media (max-width: 700px) {
  .container .text{
    font-size: 30px;
  }
  .container form{
    padding: 1em;
  }
  .container form .form-row{
    display: block;
  }
  form .form-row .input-data{
    margin: 35px 0!important;
  }
  .submit-btn .input-data{
    width: 40%!important;
  }
}

.requirements {
  padding: 0 30px 0 50px;
  color: #999;
  /* max-height: 0;
  transition: 0.28s; */
  overflow: hidden;
  color: red;
  font-style: italic;
  font-size: small;
}

.colButton {

  text-align: center;
  padding: 2%;
}

.colButton span{
  text-align: center;
  padding: 2%;
  font-size: xx-large;
}

.colButtonTxt {
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.disabled-link{
  cursor: default;
  pointer-events: none;        
  text-decoration: none;
  color: grey;

  display: inline-flex;
  align-items: flex-end;
  font-family: Arial;
  
  font-size: 2.5em;
  /* padding: 10px; */
  padding-top: 10%;
  text-decoration: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  background: #f6f6f6 !important;
  
}

.btn-large {
  display: inline-flex;
  align-items: flex-end;
  font-family: Arial;
  color: #ffffff !important;
  font-size: 2.5em;
  /* padding: 10px; */
  padding-top: 10%;
  text-decoration: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  background: #d80915 !important;
  /* height: 5em; */
}

.btn-large:hover {
  background: #697279;
  color: #d80915;
}

.btn-med {
  text-decoration: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  height: 5em;
}

#map {
  height: 100%;
}


.action-bar{
  background: #efe2e2;
  padding: 0.5em;
  text-align: right;
}
.action-bar button{
  margin: 0 10px;
}

.action-bar-left{
  background: brown;
  padding: 0.5em;
  text-align: left;
  color: white;
}

.action-bar-left a{
  color: white;
}

.lorettaTable {
  width: 100%;
  background-color: #FFFFFF;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #F6F6F6;
  border-style: solid;
  color: #000000;
}

.lorettaTable td, table.customTable th {
  border-width: 2px;
  border-color: #F6F6F6;
  border-style: solid;
  padding: 5px;
}

.lorettaTable thead {
  background-color: #F6F6F6;
}




.btn-mail{
font-family: Roboto, sans-serif;
font-weight: 0;
font-size: 14px;
color: #fff;
background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%);
padding: 10px 30px;
border: solid #e76f51 2px;
box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
border-radius: 50px;
transition : 1000ms;
transform: translateY(0);
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
}

.btn-mail:hover{
transition : 1000ms;
padding: 10px 50px;
transform : translateY(-0px);
background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%);
color: #0066cc;
border: solid 2px #0066cc;
}

.disabled-link {
  pointer-events: none;
}



/* POP-UP */

.button {
  /* font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06D85F;
  border-radius: 20px/50px;
  text-decoration: none; */
  cursor: pointer;
  transition: all 0.3s ease-out;
}
/* .button:hover {
  background: #06D85F;
} */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  text-align: -webkit-center;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 1000;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
  /* .nav-w-100{
    width: 100%!important;
  } */
  .only-pc{
    display: none;
  }
}

.thumbnail {
  background-color: black;
  width: 250px;
  height: 200px;
  display: inline-block; /* makes it fit in like an <img> */
  background-size: cover; /* or contain */
  background-position: center center;
  background-repeat: no-repeat;
}

.uploadForm{
  background-color: #efe2e2;
  padding: 10px;
}

.btAdd{
  width: 100%;
  height: -webkit-fill-available;
  margin: 10px;
}

.div-file{
  background-color: whitesmoke;
}

.text-cutted{
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
@media screen and (max-width: 980px){
  .img-logo{
    display: none;
  }  
}

