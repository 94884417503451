/* Naranja: #cc3508 
Terracota: #793100 
Gris oscuro: ##383838 
Gris Claro: #e6e6e6   */


.loretta3-navbar {
    background-color: #c8c8c8;
    color: #FFF;
}

.loretta3-banner {
    background-color: #e6e6e6;
    /* height: 20em; */
}

.carousel-caption-div {
    /* bottom: 17.25rem !important;     */
    display: none !important;

}

.carousel-indicators {
    bottom: auto !important
}

.serv {
    display: flex;
    justify-content: center;
}

.loretta3-services {
    /* background-color: aquamarine; */
    /* width: 100%; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
    /* background-color: #0666a3 */
    padding: 1rem;
    /* position: absolute; */
    margin-top: -3rem !important;
}

.loretta3-services .card {
    /* background: linear-gradient(to right, #fa7a44 , #ed7b49 ); */
    background-color: #383838;
    color: #FFF;
    height: 10rem;
    /* width: 11rem; */
    overflow: hidden;
}

.loretta3-services .card-body {
    /* background-color: gray;
    color: #FFF;
    height: 8rem;
    overflow: hidden; */
}

.loretta3-services .card-body a {
    color: #FFF;
}

.loretta3-services .card-body svg {
    color: #ee701e;
}

/* review rem */
.div3-services {
    /* position: absolute;
    top: 0; 
    margin: 30% 0; */
    /* background-color: #8ebf42 */
}

.img-responsive {
    width: 100%;
    height: auto;
}

.loretta3-news {
    /* background-color: #cc3508; */
}

.loretta3-media {
    /* background-color: #e6e6e6; */
}

.loretta3-articles {
    /* background-color: #cc3508; */
}

.loretta3-articles .card {
    /* background: #e6e6e6;  none !important; */
    background: #e1e1e1;
    height: 24rem;
    /* width: 11rem; */
    overflow: hidden;
    border: none;
}

.loretta3-articles .card img {
    height: 10rem;
}

.loretta3-articles .card-body a {
    /* background-color: none; */
    color: #383838;
}

.loretta3-footer {
    /* background-color: green;
    color: aliceblue; */
}

.loretta3-services-news {
    background-color: #cc3508;
    position: relative;
}

.list-group-item{
    background: none !important;
}