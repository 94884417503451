@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

.div-services * {
	box-sizing: border-box;
}

.div-services {
	--h: 212deg;
	--l: 43%;
	--brandColor: hsl(var(--h), 71%, var(--l));
	font-family: Montserrat, sans-serif;
	margin: 0;
	background-color: whitesmoke;
}

.div-services p {
	margin: 0;
	line-height: 1.6;
}

.div-services ol {
	list-style: none;
	counter-reset: list;
	padding: 0 1rem;
}

.div-services li {
	--stop: calc(100% / var(--length) * var(--i));
	--l: 62%;
	--l2: 88%;
	--h: calc((var(--i) - 1) * (180 / var(--length)));
	--c1: hsl(var(--h), 71%, var(--l));
	--c2: hsl(var(--h), 71%, var(--l2));
	
	position: relative;
	counter-increment: list;
	max-width: 45rem;
	margin: 2rem auto;
	padding: 2rem 1rem 1rem;
	box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.3);
	border-radius: 0.25rem;
	overflow: hidden;
	background-color: white;
}

.div-services li::before {
	content: '';
	display: block;
	width: 100%;
	height: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, var(--c1) var(--stop), var(--c2) var(--stop));
}

.div-services h4 {
	display: flex;
	align-items: baseline;
	margin: 0 0 1rem;
	color: rgb(70 70 70);
}

.div-services h4::before {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	margin-right: 1rem;
	width: 3rem;
	height: 3rem;
	content: counter(list);
	padding: 1rem;
	border-radius: 50%;
	background-color: var(--c1);
	color: white;
}

.div-services .ol{
    --length: 5
}

.div-services .li1{
    --i: 1
}
.div-services .li2{
    --i: 2
}

.div-services .li3{
    --i: 3
}

.div-services .li4{
    --i: 4
}

.div-services .li5{
    --i: 5
}

.div-services .li6{
    --i: 5
}

.div-services .li5{
    --i: 6
}

.div-services .li7{
    --i: 7
}

.div-services .li8{
    --i: 8
}

.div-services .li9{
    --i: 9
}


@media (min-width: 40em) {
	.div-services li {
		margin: 3rem auto;
		padding: 3rem 2rem 2rem;
	}
	
	.div-services h3 {
		font-size: 2.25rem;
		margin: 0 0 2rem;
	}
	
	.div-services h3::before {
		margin-right: 1.5rem;
	}
}

